import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { VoyoSliderWithData } from '@hmn/rtl-web-core/components/VoyoSlider/VoyoSlider.component'

import Image from '@hmn/rtl-net-ui/components/Image/Image.component'
import { toRems } from '@hmn/rtl-net-ui/helpers'

import { Cell } from '../LayoutBuilder.style'

function WidgetVoyoSlider({ position, isWide, id, deviceProp, device }) {
    const isAmp = useAmp()
    if (!id || isAmp) {
        return null
    }
    if (!position) {
        return (
            <VoyoSliderWithData
                ImageComponent={Image}
                isWide={isWide}
                id={id}
                deviceProp={deviceProp}
                device={device}
            />
        )
    }
    return (
        <Cell
            position={position}
            noPadding
            style={{
                position: 'relative',
                alignSelf: 'stretch',
                padding: 0,
                margin: toRems([0, 0, 15])
            }}>
            <VoyoSliderWithData
                isWidget
                ImageComponent={Image}
                isWide={position?.w === 12 && isWide}
                id={id}
                deviceProp={deviceProp}
                device={device}
            />
        </Cell>
    )
}
WidgetVoyoSlider.propTypes = {
    position: PropTypes.shape({ w: PropTypes.number, h: PropTypes.number }),
    deviceProp: PropTypes.string,
    device: PropTypes.string,
    isWide: PropTypes.bool,
    id: PropTypes.string
}
WidgetVoyoSlider.defaultProps = {
    position: undefined,
    deviceProp: undefined,
    device: undefined,
    isWide: true,
    id: undefined
}

export default WidgetVoyoSlider
